// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-company-tsx": () => import("../src/templates/Company.tsx" /* webpackChunkName: "component---src-templates-company-tsx" */),
  "component---src-templates-company-404-tsx": () => import("../src/templates/Company404.tsx" /* webpackChunkName: "component---src-templates-company-404-tsx" */),
  "component---src-templates-product-tsx": () => import("../src/templates/Product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */),
  "component---node-modules-nettbureau-gatsby-theme-katalog-src-pages-index-tsx": () => import("../node_modules/@nettbureau/gatsby-theme-katalog/src/pages/index.tsx" /* webpackChunkName: "component---node-modules-nettbureau-gatsby-theme-katalog-src-pages-index-tsx" */),
  "component---src-pages-stromleverandorer-tsx": () => import("../src/pages/strømleverandører.tsx" /* webpackChunkName: "component---src-pages-stromleverandorer-tsx" */)
}

